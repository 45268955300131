<template>
  <div class="contact-complete">
    <BackGround />
    <div class="container">
      <router-link to="/"><h1>stobita</h1></router-link>
      <h2>Contact</h2>
      <p class="notification">メッセージの送信が完了しました。</p>
      <ContactForm />
    </div>
  </div>
</template>

<script>
import BackGround from "@/components/BackGround";
import ContactForm from "@/components/ContactForm";
export default {
  components: { BackGround, ContactForm }
};
</script>

<style lang="sass" scoped>
h1,h2,div,a
  z-index: 1
h1
  color: $main-black
h2
  margin-top: 8px
  margin-bottom: 0px
a
  text-decoration: none
.contact-complete
  height: 100%
.container
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  flex-direction: column
.notification
  font-weight: 600
  font-size: 1.2rem
  margin-top: 56px
  padding-bottom: 8px
  border-bottom: 3px solid $accent
</style>
