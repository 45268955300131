<template>
  <div class="form">
    <form
      name="contact"
      method="POST"
      action="/contact/complete"
      netlify
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />
      <p class="hidden">
        <label
          >Don’t fill this out if you're human: <input name="bot-field"
        /></label>
      </p>
      <p class="item">
        <label :class="{ input: isInput.name, focus: isFocus.name }"
          >Name</label
        >
        <input
          type="text"
          name="name"
          @focus="focus('name')"
          @blur="blur('name')"
          v-model="value.name"
        />
      </p>
      <p class="item">
        <label :class="{ input: isInput.email, focus: isFocus.email }"
          >Email</label
        >
        <input
          type="email"
          name="email"
          @focus="focus('email')"
          @blur="blur('email')"
          v-model="value.email"
        />
      </p>
      <p class="item">
        <label :class="{ input: isInput.message, focus: isFocus.message }"
          >Message</label
        >
        <textarea
          name="message"
          @focus="focus('message')"
          @blur="blur('message')"
          v-model="value.message"
        />
      </p>
      <div data-netlify-recaptcha="true"></div>
      <p class="submit">
        <button type="submit"><span class="text">Send</span></button>
      </p>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: {
        name: "",
        email: "",
        message: ""
      },
      isFocus: {
        name: false,
        email: false,
        message: false
      },
      isInput: {
        name: false,
        email: false,
        message: false
      }
    };
  },
  methods: {
    focus(target) {
      this.isInput[target] = true;
      this.isFocus[target] = true;
    },
    blur(target) {
      if (this.value[target] !== "") {
        this.isFocus[target] = false;
        return;
      }
      this.isInput[target] = false;
      this.isFocus[target] = false;
    }
  }
};
</script>

<style lang="sass" scoped>
form
  width: 100%
input,textarea, button
  appearance: none
  border: none
textarea
  resize: none
input[type="text"],
input[type="email"],
textarea
  outline: none
  border: 1px solid $light-grey
  -webkit-transition: all .3s
  transition: all .3s
input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus
  box-shadow: none
  border: 1px solid $accent
.form
  display: flex
  justify-content: center
  max-width: 480px
  width: 100%
  padding: 16px
  position: relative
  display: flex
  margin: 0 8px
  box-sizing: border-box
.item
  position: relative
  width: 100%
  margin-bottom: 26px
  input,textarea
    width: 100%
    padding: 12px
    box-sizing: border-box
    font-family: 'Noto Sans JP', sans-serif;
  textarea
    height: 200px
  label
    position: absolute
    transition: all 0.5s ease
    top: 15px
    left: 10px
    pointer-events: none
    color: $main-black
    &.input
      top: -18px
      left: 5px
      font-size: 0.8rem
    &.focus
      color: $accent
.submit
  display: flex
  justify-content: center
  button
    padding: 8px 32px
    color: $thin-grey
    background: $main-black
    &:hover
      transition: all 0.3s ease-out
      background: $accent
    .text
      display: block
      padding-top: 2px
.hidden
  display: none
</style>
