import { render, staticRenderFns } from "./BackGround.vue?vue&type=template&id=7a0b7e09&scoped=true&"
var script = {}
import style0 from "./BackGround.vue?vue&type=style&index=0&id=7a0b7e09&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a0b7e09",
  null
  
)

export default component.exports