<template>
  <div class="detail">
    <BackGround></BackGround>
    <div class="container">
      <router-link to="/" class="button"><h1>stobita</h1></router-link>
      <h2 class="profile">Profile</h2>
      <Profile />
      <h2 class="history">History</h2>
      <ul class="histories">
        <li v-for="history in histories" :key="history.id">
          <CareerItem :data="history" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CareerItem from "@/components/CareerItem";
import HistoryData from "@/assets/history.json";
import BackGround from "@/components/BackGround";
import Profile from "@/components/Profile";
export default {
  name: "detail",
  components: { CareerItem, BackGround, Profile },
  data() {
    return {
      histories: HistoryData
    };
  }
};
</script>

<style lang="sass" scoped>
h1,h2,div
  z-index: 1
h1
  margin-top: 32px
  margin-bottom: 8px
  color: $main-black
a
  text-decoration: none
h2
  &.profile
    margin-top: 24px
  &.history
    margin-top: 24px
    margin-bottom: 4px
.detail
  min-height: 100%
  display: flex
  justify-content: center
  align-items: center
.container
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100%
  max-width: 800px
  width: 100%
  padding: 8px
  box-sizing: border-box;
.histories
  width: 100%
</style>
