<template>
  <div class="main">
    <div>
      <p class="title">期間</p>
      <p class="content">
        {{ periodText }}
      </p>
    </div>
    <div>
      <p class="title" v-if="data.lang || data.fw || data.other">利用技術</p>
      <div class="content">
        <p v-if="data.lang">
          <span v-for="(lang, index) in data.lang" :key="index">
            <span> {{ lang }} </span>
            <span v-if="index !== data.lang.length - 1">/</span>
          </span>
        </p>
        <p v-if="data.fw">
          <span v-for="(fw, index) in data.fw" :key="index">
            <span> {{ fw }} </span>
            <span v-if="index !== data.fw.length - 1">/</span>
          </span>
        </p>
        <p v-if="data.other">
          <span v-for="(other, index) in data.other" :key="index">
            <span> {{ other }} </span>
            <span v-if="index !== data.other.length - 1">/</span>
          </span>
        </p>
      </div>
    </div>
    <div v-if="data.tool">
      <p class="title">ツール等</p>
      <p class="content">
        {{ data.tool.join(",") }}
      </p>
    </div>
    <div v-if="data.work">
      <p class="title">業務概要</p>
      <p v-for="(work, index) in data.work" :key="index" class="content desc">
        {{ work }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    periodText() {
      const start = `${this.data.start.year}/${this.data.start.month}`;
      const end = this.data.end
        ? `${this.data.end.year}/${this.data.end.month}`
        : "継続中";
      return `${start}~${end}`;
    }
  }
};
</script>

<style lang="sass" scoped>
.main
  padding: 8px
.title
  font-weight: 800
.content
  margin-left: 8px
  font-size: .9rem
.desc
</style>
