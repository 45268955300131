import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Career from "./views/Career.vue";
import Contact from "./views/Contact.vue";
import ContactComplete from "./views/ContactComplete.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/career",
      name: "career",
      component: Career
    },
    {
      path: "/contact",
      name: "contact",
      component: Contact
    },
    {
      path: "/contact/complete",
      name: "contact-complete",
      component: ContactComplete
    }
  ]
});
