<template>
  <div class="item">
    <div class="header" @click="toggle">
      <div class="header-main">
        <div class="header-item title">
          {{ data.title }}
        </div>
        <div class="header-item">
          <p class="main-tech">
            {{ data.mainTech.join("/") }}
          </p>
        </div>
      </div>
      <div class="opener" :class="{ active: open }">
        <span></span><span></span>
      </div>
    </div>

    <transition
      name="expand"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div v-if="open" class="body" :class="{ active: open }">
        <CareerItemDetail :data="data" />
      </div>
    </transition>
  </div>
</template>

<script>
import CareerItemDetail from "@/components/CareerItemDetail";
export default {
  props: ["data"],
  components: {
    CareerItemDetail
  },
  data() {
    return { open: false };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = "0";
    }
  }
};
</script>

<style lang="sass" scoped>
.item
  color: $grey
  margin-bottom: 8px
  display: flex
  flex-direction: column
  width: 100%
  padding: 8px
  border: 1px solid $light-grey
  box-sizing: border-box;
  background: $thin-grey
  transition: all .3s ease-out
  &:hover
    filter: drop-shadow(8px 8px 8px $light-grey)
    transition: all .3s ease-out
  .header
    display: flex
    align-items: center
    justify-content: space-between
    cursor: pointer
    font-size: 1rem
    padding: 8px
    .header-item
      flex: 1
    .title
      margin-bottom: 8px
      font-weight: 600
    .main-tech
      padding-top: 2px;
      margin: 0
  .opener
    position: relative
    width: 30px
    height: 30px
    span
      top: 50%
      right: 8px
      position: absolute
      width: 50%
      height: 2px
      background: $grey
      border-radius: 4px
      transition: all .4s
      &:nth-of-type(1)
        transform: rotate(90deg)
      &:nth-of-type(2)
    &.active
      span
        &:nth-of-type(1)
          display: none
        &:nth-of-type(2)
          transform: rotate(180deg)
  .body
    transition: height 0.5s ease-out;
    overflow: hidden
  .expand
    &-enter-active
      animation-name: open
      animation-duration: 1s
      animation-fill-mode: both
      animation-timing-function: ease-in
    &-leave-active
      animation-name: close
      animation-duration: 1s
      animation-fill-mode: both
      animation-timing-function: ease-out

  @media screen and (min-width: 480px)
    .header-main
      display: flex
      flex: 1
      .title
        margin-bottom: 0px
@keyframes open
  0%
    opacity: 0
  100%
    opacity: 1
@keyframes close
  0%
    opacity: 1
  100%
    opacity: 0
</style>
