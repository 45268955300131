<template>
  <div class="contact">
    <BackGround />
    <div class="container">
      <router-link to="/"><h1>stobita</h1></router-link>
      <h2>Contact</h2>
      <ContactForm />
    </div>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";
import BackGround from "@/components/BackGround";
export default {
  name: "contact",
  components: { BackGround, ContactForm }
};
</script>

<style lang="sass" scoped>
h1,h2,div,a
  z-index: 1
h1
  color: $main-black
h2
  margin-top: 8px
  margin-bottom: 0px
a
  text-decoration: none
.contact
  height: 100%
.container
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  flex-direction: column
</style>
