<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "normalize.css";
export default {};
</script>

<style lang="sass">
// @import url('https://fonts.googleapis.com/css?family=Aldrich|Electrolize|Iceland|Josefin+Sans|Noto+Sans+JP:400,700')
@import url('https://fonts.googleapis.com/css?family=Aldrich|Noto+Sans+JP:400,700')
html,body,#app
  height: 100%
  min-height: 100%; /* 最小の高さ */
  font-family: 'Noto Sans JP', sans-serif;
html
  background: $thin-grey
h1,h2,p,a
  //font-family: 'Iceland', sans-serif;
  //font-family: 'Electrolize', sans-serif;
  font-family: 'Aldrich', sans-serif;
  color: $main-black
  // font-family: 'Josefin Sans', sans-serif;
ul
  padding: 0
li
  list-style-type: none
</style>
