<template>
  <div class="home">
    <BackGround />
    <div class="container">
      <h1>
        stobita
      </h1>
      <h2>
        Software Engineer
      </h2>
      <div class="icons">
        <ContactIcon />
        <GitHubIcon />
        <TwitterIcon />
      </div>
      <div style="text-align: center">
        <p>
          Golang/JavaScript
        </p>
        <p>
          TypeScript/Vue/React/Laravel/ReactNative/Node
        </p>
        <p>
          AWS/Docker/GraphQL/gRPC
        </p>
      </div>
      <div>
        <DetailButton />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import BackGround from "@/components/BackGround";
import GitHubIcon from "@/components/GitHubIcon";
import TwitterIcon from "@/components/TwitterIcon";
import ContactIcon from "@/components/ContactIcon";
import DetailButton from "@/components/DetailButton";

export default {
  name: "home",
  components: { BackGround, GitHubIcon, TwitterIcon, ContactIcon, DetailButton }
};
</script>

<style lang="sass" scoped>
h1,h2,div
  z-index: 1
h1
  margin-bottom: 0
.home
  height: 100%
.container
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  flex-direction: column
.icons
  display: flex
  align-items: center
</style>
